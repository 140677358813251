.ui-button-group {
    display: flex;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: var(--border-radius);
}

.ui-button-group .ui-button,
.ui-button-group .ui-select .select-button,
.ui-button-group .ui-text-input input {
    box-shadow: none;
    margin: 0;
}

.ui-button-group .ui-button:not(:last-child),
.ui-button-group .ui-select:not(:last-child) .select-button,
.ui-button-group .ui-text-input:not(:last-child) input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.ui-button-group .ui-button:not(:first-child),
.ui-button-group .ui-select:not(:first-child) .select-button,
.ui-button-group .ui-text-input:not(:first-child) input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    
}

.ui-button-group .ui-button:not(:first-child) {
    margin-left: -1px;
}

.ui-button-group .ui-select:not(:first-child),
.ui-button-group .ui-text-input:not(:first-child) {
    margin-left: -1px;
}
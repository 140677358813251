.journey {
    display: flex;
    align-items: stretch;
    height: 100%;
}

.journey-actions {
    display: flex;
}

.journey-builder {
    background-size: 30px 30px;
    flex-grow: 1;
}

.journey-options {
    max-width: 300px;
    border-color: var(--color-grey);
    border-style: solid;
    border-width: 0;
    border-left-width: 1px;
    position: relative;
    overflow: scroll;
}

.journey-options-edit {
    padding: 15px 20px;
}

.editing .journey-options {
    max-width: none;
}

.journey-options h4 {
    padding: 10px 20px;
    margin: 0;
}

.journey-options .options-section {
    padding: 10px 20px;
}

.journey-options .component {
    display: grid;
    grid-template-areas: "handle title" "handle desc";
    grid-template-columns: auto 1fr;
    border-bottom: 1px solid var(--color-grey);
    padding: 20px;
    column-gap: 15px;
    cursor: grab;
}

.journey-options .component:hover {
    background-color: var(--color-background-soft);
}

.journey-options .component:last-child {
    border-bottom: 0px;
}

.journey-options .journey-step-header {
    padding: 15px 20px;
}

.journey-options .journey-step-header h4 {
    font-size: 18px;
    line-height: 22px;
}

.component-handle {
    background-color: var(--color-grey);
    grid-area: handle;
    align-self: start;
    border-radius: 4px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.component-handle svg {
    width: 16px;
    height: 16px;
}

.component.entrance .component-handle,
.journey-step.entrance .step-header-icon,
.component.exit .component-handle,
.journey-step.exit .step-header-icon {
    background-color: var(--color-red-soft);
    color: var(--color-red-hard);
}

.component.entrance .component-handle svg, .journey-step.entrance .step-header-icon svg {
    margin-left: -4px;
}

.component.delay .component-handle, .journey-step.delay .step-header-icon {
    background-color: var(--color-yellow-soft);
    color: var(--color-yellow-hard);
}

.component.action .component-handle, .journey-step.action .step-header-icon {
    background-color: var(--color-blue-soft);
    color: var(--color-blue-hard);
}

.component.flow .component-handle, .journey-step.flow .step-header-icon {
    background-color: var(--color-green-soft);
    color: var(--color-green-hard);
}

.journey-minimap.entrance,
.journey-minimap.exit {
    fill: var(--color-red-soft);
}

.journey-minimap.entrance.selected,
.journey-minimap.exit.selected {
    fill: var(--color-red);
}

.journey-minimap.delay {
    fill: var(--color-yellow-soft);
}

.journey-minimap.delay.selected {
    fill: var(--color-yellow);
}

.journey-minimap.action {
    fill: var(--color-blue-soft);
}

.journey-minimap.action.selected {
    fill: var(--color-blue);
}

.journey-minimap.flow {
    fill: var(--color-green-soft);
}

.journey-minimap.flow.selected {
    fill: var(--color-green);
}

.journey .react-flow__minimap {
    background-color: var(--color-background-soft);
}

.journey .react-flow__minimap-mask {
    fill: var(--color-background);
    opacity: 0.6;
}

.component-title {
    grid-area: title;
    font-weight: bold;
}

.component-desc {
    font-size: 14px;
}

.internal-canvas {
    background: var(--color-background-soft);
}

.editing .internal-canvas {
    background: var(--color-grey);
}

.journey-step {
    background: var(--color-background);
    border: 1px solid var(--color-grey);
    box-shadow: 0px 4px 30px rgba(22, 33, 74, 0.05);
    border-radius: 8px;
    min-width: 200px;
}

.editing .journey-step {
    opacity: 0.5;
}

.editing .journey-step.editing {
    opacity: 1;
}

.journey-step.entrance.selected,
.journey-step.exit.selected {
    border-color: var(--color-red-hard);
}

.journey-step.flow.selected {
    border-color: var(--color-green-hard);
}

.journey-step.action.selected {
    border-color: var(--color-blue-hard);
}

.journey-step.delay.selected {
    border-color: var(--color-yellow-hard);
}

.journey-step .data-key {
    background-color: var(--color-background-soft);
    padding: 7px 10px;
    border-radius: var(--border-radius-inner);
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--color-primary-soft);
}

.journey-step .data-key svg {
    height: 16px;
    margin-right: 5px;
}

.journey-step .connectors {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    bottom: -6px;
    width: 100%;
}

.journey-step .connector {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.journey-step .connector .connector-anchor {
    content: '';
    border-radius: 50%;
    width: 12px;
    height: 12px;
    z-index: 0;
    border: 1px solid var(--color-border);
    background: var(--color-background);
}

.journey-step-header {
    padding: 10px 15px;
    border-bottom: 1px solid var(--color-grey);
    display: flex;
    align-items: center;
    gap: 10px;
}

.journey-step-header .step-header-icon {
    padding: 5px;
    border-radius: 4px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.journey-step-header .step-header-title {
    flex-grow: 1;
}

.journey-step-header .step-header-options {
    flex-shrink: 0;
}

.journey-step-header h4 {
    padding: 5px 0;
    margin: 0;
    font-size: 16px;
    line-height: 20px;
}

.journey-step-header .step-header-stats {
    display: flex;
    align-items: center;
    gap: 10px;
    background: var(--color-background-soft);
    border-radius: var(--border-radius-inner);
    padding: 5px 10px;
}

.journey-step-header .step-header-stats:hover .stat {
    color: var(--color-primary);
}

.journey-step-header .step-header-stats .stat {
    display: flex;
    align-items: center;
    gap: 2px;
    font-weight: 500;
    color: var(--color-primary-soft);
}

.journey-step-body {
    padding: 20px;
}

.journey-step-body-name {
    margin-bottom: 10px;
    font-weight: bold;
}

.journey-step-edge {
    background-color: var(--color-background);
    padding: 10px;
    border: 1px solid var(--color-grey);
    border-radius: 8px;
    pointer-events: all;
}

.journey .react-flow__handle {
    width: 10px;
    height: 10px;
}

.journey .react-flow__handle-connecting {
    background: var(--color-red)
}

.journey .react-flow__handle-valid {
    background: var(--color-green)
}

.journey .react-flow__edge-path,
.journey .react-flow__connection-path {
    stroke-width: 4;
}

.journey-step-labelled-sources {
    padding-bottom: 36px;
}

.step-handle-label {
    position: absolute;
    bottom: 10px;
    transform: translate(-50%, 0)
}

.react-flow__attribution {
    display: none;
}
.ui-stack {
    --spacing: 10px;
    display: flex;
    flex-direction: row;
    gap: var(--spacing);
    margin-bottom: var(--spacing);
}

.ui-stack.ui-stack-vertical {
    flex-direction: column;
}

.ui-pagination {
    background: var(--color-background);
    border: 1px solid var(--color-grey);
    display: inline-flex;
    border-radius: var(--border-radius);
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    margin-top: 10px;
    overflow: hidden;
}

.ui-pagination > * {
    padding: 10px 15px;
    border: 0;
    border-right: 1px solid var(--color-grey);
}

.ui-pagination .pagination-button {
    background: var(--color-background);
    font-size: 16px;
    line-height: 15px;
    cursor: pointer;
    text-decoration: none;
    color: var(--color-primary);
    margin: 0;
}

.ui-pagination .pagination-button:hover {
    background: var(--color-grey-soft);
}

.ui-pagination .pagination-button:disabled {
    color: var(--color-primary-soft);
    cursor: not-allowed;
}

.ui-pagination .pagination-button:disabled:hover {
    background: var(--color-background);
}

.ui-pagination .pagination-button.selected {
    background: var(--color-grey-soft);
    font-weight: 500;
}

.ui-pagination .prev,
.ui-pagination .next {
    display: flex;
    align-items: center;
    gap: 5px;
}

.ui-pagination .prev svg,
.ui-pagination .next svg {
    width: 16px;
}

.ui-pagination .next {
    border-right-width: 0px;
}

.ui-pagination .next svg {
    width: 16px;
}

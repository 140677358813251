.modal {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
}

.modal-wrapper {
    position: absolute;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.2s ease;
}

.modal-inner {
    border-radius: var(--border-radius-outer);
    background: var(--color-background);
    width: 100%;
    max-width: 440px;
    padding: 30px;
    box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.12);
    max-height: 90vh;
    overflow-y: scroll;
    position: relative;
}

.modal.regular .modal-inner {
    max-width: 600px;
}

.modal.large .modal-inner {
    max-width: 960px;
}

.modal.fullscreen .modal-inner {
    max-height: 100vh;
    max-width: 100vw;
    height: 100vh;
    width: 100vw;
    border-radius: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
}

.modal-inner .modal-header {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.modal.fullscreen .modal-header {
    margin-bottom: 0;
}

.modal-inner .modal-header > * + * {
    margin-left: 15px;
}

.modal.fullscreen .modal-header {
    padding: 10px 20px;
    border-bottom: 1px solid var(--color-grey);
}

.modal.fullscreen .modal-header .modal-fullscreen-actions {
    display: flex;
    gap: 10px;
    align-items: center;
}

.modal.fullscreen .modal-content {
    flex-grow: 1;
    position: relative;
    overflow: auto;
}

.modal .modal-close {
    position: absolute;
    top: 30px;
    right: 25px;
}

.modal .modal-close .button-icon,
.modal .modal-close .button-icon svg {
    width: 20px;
    height: 20px;
}

.modal-inner .modal-header h3 {
    margin-top: 0;
    margin-bottom: 0;
    flex-grow: 1;
}

.modal-inner .modal-footer {
    margin-top: 20px;
}

.modal-overlay {
    background: rgba(0,0,0,0.3);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
}

.modal .transition-enter {
    transition: opacity .1s cubic-bezier(.4,0,.2,1), transform .1s ease-out;
}

.modal .transition-leave {
    transition: opacity .1s ease-in, transform .1s ease-in;
}

.modal .transition-leave-from,
.modal .transition-enter-to {
    opacity: 1;
}

.modal .transition-leave-to,
.modal .transition-enter-from {
    opacity: 0;
}

.modal .transition-enter-from-scale {
    transform: scale(1.05);
}
.preview-source-editor {
    background: var(--color-editor);
    display: flex;
    position: relative;
    align-items: stretch;
    border-radius: var(--border-radius);
    overflow: auto;
    padding: 10px 0;
    min-height: 400px;
    flex-grow: 1;
}

.preview-source-editor section {
    height: auto !important;
}

.user-lookup .ui-button-group {
    width: 100%;
    margin-bottom: 10px;
}

.user-lookup .ui-button-group .ui-text-input {
    flex-grow: 1;
}
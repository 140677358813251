.auth {
    background: var(--color-background-soft);
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    flex-direction: column;
    gap: 15px;
    padding: 40px 0;
}

.auth .logo svg {
    height: 40px;
}

.auth-step {
    min-width: 400px;
    max-width: 600px;
    background: var(--color-background);
    border: 1px solid var(--color-grey-soft);
    border-radius: var(--border-radius-outer);
    padding: 40px;
}

.auth.login .auth-step {
    min-width: 300px;
    width: 100%;
    max-width: 350px;
}

.auth-step h1, .auth-step h2 {
    margin: 0;
}

.auth-step .form {
    padding: 10px 0;
}

.auth-methods {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.auth.login .ui-button,
.auth.login .form-submit {
    width: 100%;
}

.auth.login form {
    margin-bottom: 10px;
}

@media only screen and (max-width: 600px) {
    .auth {
        padding: 40px 20px;
    }

    .auth-step {
        min-width: auto;
        width: 100%;
    }
}
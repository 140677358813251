.ui-tile-grid {
    display: grid;
    gap: 10px;
    margin: 15px 0;
}

.ui-tile {
    border: 1px solid var(--color-grey);
    border-radius: var(--border-radius);
    padding: 20px;
    transition: box-shadow ease-in .1s;
    display: flex;
    gap: 10px;
    align-items: center;
}

.ui-tile.interactive { cursor: pointer; }
.ui-tile.interactive:hover {
    box-shadow: 0 3px 10px rgba(0,0,0,0.1);
}

.ui-tile.selected {
    border: 1px solid var(--color-primary);
}

.ui-tile img {
    border-radius: var(--border-radius);
    width: 100%;
    max-width: 40px;
    flex-shrink: 0;
}

.ui-tile-text {
    flex-grow: 1;
}

.ui-tile h5 {
    margin: 0 0 5px;
}

.ui-tile p {
    font-size: 13px;
    margin: 0;
    color: var(--color-primary-soft);
}

.ui-tile.large {
    padding: 30px 20px;
}

.ui-tile.large h5 {
    font-size: 30px;
    margin: 0 0 10px;
}

@media only screen and (max-width: 600px) {
    .ui-tile-grid {
        grid-template-columns: 1fr !important;
    }
}
.ui-tabs {
    position: relative;
}

.ui-tabs::before {
    content: '';
    width: 100%;
    bottom: 0;
    height: 1px;
    left: 0;
    position: absolute;
    border-bottom: 1px solid var(--color-grey);
    z-index: 0;
}

.ui-tabs .tab {
    background: none;
    color: var(--color-primary-soft);
    font-size: 15px;
    margin-right: 20px;
    font-weight: 500;
    padding: 12px 5px;
    display: inline-block;
    border: 0;
    border-bottom: 3px solid transparent;
    cursor: pointer;
    text-decoration: none;
    z-index: 10;
    position: relative;
}

.ui-tabs .tab:hover {
    color: var(--color-primary);
    border-bottom: 3px solid var(--color-grey);
}

.ui-tabs .tab.selected {
    color: var(--color-primary);
    border-bottom: 3px solid var(--color-on-background);
}

.ui-tabs-panels .panel {
    width: 100%;
    display: flex;
}

.ui-text-input.disabled input,
.ui-text-input.disabled textarea {
    cursor: not-allowed;
    opacity: 0.5 !important;
}


.ui-text-input.disabled input:hover,
.ui-text-input.disabled textarea:hover {
    border-color: var(--color-grey);
}

.ui-text-input-icon-wrapper {
    position: relative;
}

.ui-text-input-icon-wrapper input,
.ui-text-input-icon-wrapper textarea {
    padding-left: 36px;
}

.ui-text-input-icon {
    position: absolute;
    top: 50%;
    left: 12px;
    height: 16px;
    width: 16px;
    transform: translate(0, -50%);
}

.ui-text-input-suffix-wrapper {
    width: 100%;
    display: flex;
}

.ui-text-input-suffix-wrapper input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: auto;
    flex-grow: 1;
}

.ui-text-input-suffix {
    border: 1px solid var(--color-grey);
    border-left-width: 0;
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    padding: 12px 15px;
}
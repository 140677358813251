.ui-table {
    width: 100%;
    display: table;
    border-radius: var(--border-radius);
    border: 1px solid var(--color-grey);
    overflow: hidden;
}

.ui-table .table-row, .ui-table .table-header {
    display: table-row;
}

.ui-table .table-header {
    background: var(--color-background-soft);
}

.ui-table .table-row:hover .table-cell {
    background: var(--color-background-soft);
}

.ui-table .table-row.table-row-selected .table-cell {
    background: var(--color-grey-soft);
}

.ui-table .table-row-interactive {
    cursor: pointer;
}

.ui-table .table-cell, .ui-table .table-header-cell {
    display: table-cell;
    border-bottom: 1px solid var(--color-grey);
}

.ui-table .table-header-cell {
    padding: 10px 5px;
    white-space: nowrap;
    vertical-align: center;
}

.ui-table .table-header-cell .header-cell-content {
    display: flex;
    gap: 5px;
    align-items: center;
}

.ui-table .table-header-cell .header-sort {
    display: inline-flex;
    border-radius: var(--border-radius-inner);
    padding: 3px;
    cursor: pointer;
}

.ui-table .table-header-cell .header-sort:hover {
    background: var(--color-grey);
}

.ui-table .table-cell {
    padding: 15px 5px;
}

.ui-table .table-cell:first-child, .ui-table .table-header-cell:first-child {
    padding-left: 15px;
}

.ui-table .table-cell:last-child, .ui-table .table-header-cell:last-child {
    padding-right: 15px;
}

.ui-table .table-row:last-child .table-cell {
    border-bottom: 0px;
    padding-right: 15px;
}

.ui-table .table-cell .cell-content {
    display: flex;
    align-items: center;
    gap: 5px;
}

.ui-table .table-header-cell {
    font-weight: 500;
}

.ui-table .table-cell {
    vertical-align: middle;
    overflow-wrap: anywhere;
}

.ui-table .table-cell .multi-cell {
    display: grid;
    grid-template-areas: "image text";
    grid-template-columns: 50px auto;
    align-items: center;
    column-gap: 10px;
}

.ui-table .table-cell .multi-cell.no-image {
    grid-template-areas: "text text";
}

.ui-table .table-cell .multi-cell .text {
    grid-area: text;
}

.ui-table .table-cell .multi-cell .title {
    font-weight: 500;
}

.ui-table .table-cell .multi-cell .subtitle {
    font-size: 14px;
    color: var(--color-primary-soft);
    margin-top: 3px;
}

.ui-table .table-cell .multi-cell object,
.ui-table .table-cell .multi-cell .placeholder {
    background-color: var(--color-background-soft);
    color: var(--color-grey-hard);
    grid-area: image;
    width: 100%;
    height: 40px;
    object-fit: cover;
    object-position: 0px 0px;
    border-radius: var(--border-radius-inner);
}

.ui-table .table-cell .multi-cell .placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ui-table .table-cell .multi-cell .placeholder .icon {
    width: 24px;
    height: 24px;
}

.ui-table .table-cell .loader {
    background-color: var(--color-grey-soft);
    height: 18px;
    border-radius: 7px;
    width: 100%;
    background: linear-gradient(100deg,
    rgba(255, 255, 255, 0) 40%,
    rgba(255, 255, 255, .8) 50%,
    rgba(255, 255, 255, 0) 60%) var(--color-grey-soft);
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1s loading ease-in-out infinite;
    opacity: 0.7;
}

.ui-table .table-row.loading:hover .table-cell {
    background: transparent;
    cursor: default;
}

@keyframes loading {
    to {
        background-position-x: -30%;
    }
}
.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 225px;
    background: var(--color-background);
    border-right: 1px solid var(--color-grey);
    display: flex;
    flex-direction: column;
    z-index: 1;
}

main {
    margin-left: 225px;
}

header, .sidebar .sidebar-header {
    display: flex;
    position: relative;
    border-bottom: 1px solid var(--color-grey);
    padding: 15px 20px;
    gap: 10px;
    align-items: center;
}

header .logo, .sidebar-header .logo {
    display: flex;
    align-items: center;
}

header .logo svg, .sidebar-header .logo svg {
    height: 30px;
    flex-shrink: 0;
    fill: var(--color-primary);
}

.sidebar .project-switcher.select-button {
    padding: 15px 20px;
    border-radius: 0;
    border-width: 0 0 1px;
    border-color: var(--color-grey);
}

.project-switcher-label {
    color: var(--color-primary-soft);
    font-size: 12px;
}

.project-switcher-value {
    color: var(--color-primary);
    font-size: 14px;
    font-weight: 500;
}

header {
    display: none;
}

nav {
    padding: 20px;
    flex-grow: 1;
    overflow-y: scroll;
}

nav a {
    display: flex;
    padding: 15px;
    text-decoration: none;
    color: var(--color-primary);
    border-radius: var(--border-radius);
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 5px;
    align-items: center;
    gap: 5px;
}

nav a:hover {
    color: var(--color-primary);
    background: var(--color-grey);
    text-decoration: none;
}

nav a.selected {
    color: var(--color-on-primary);
    background: var(--color-primary);
}

nav a .nav-icon {
    width: 16px;
    height: 16px;
    display: flex;
    margin-right: 4px;
}

.sidebar-profile {
    border-top: 1px solid var(--color-grey);
    width: 100%;
}

.sidebar-profile .sidebar-profile-inner {
    display: grid;
    grid-template-areas: "image name caret" "image role caret";
    grid-template-columns: 40px 1fr;
    grid-column-gap: 5px;
    grid-row-gap: 0px;
    align-items: center;
    cursor: pointer;
    padding: 15px 20px;
}

.sidebar-profile .sidebar-profile-inner:hover {
    background-color: var(--color-background-soft);
}

.sidebar-profile .profile-image {
    width: 40px;
    height: 40px;
    background: var(--color-grey);
    border-radius: 20px;
    grid-area: image;
    overflow: hidden;
}

.sidebar-profile .profile-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.sidebar-profile .profile-name {
    grid-area: name;
    font-size: 14px;
    padding-left: 10px;
}

.sidebar-profile .profile-role {
    grid-area: role;
    font-size: 13px;
    font-weight: 500;
    display: flex;
    padding-left: 10px;
}

.sidebar-profile .profile-caret {
    grid-area: caret;
}

@media only screen and (max-width: 600px) {
    header {
        background: var(--color-background);
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 10;
        gap: 15px;
    }

    .sidebar {
        width: 90%;
        top: 61px;
        margin-left: -90%;
        transition: margin-left 100ms ease-in-out;
    }

    main {
        padding-top: 61px;
        background-color: var(--color-background);
        margin-left: 0;
        transition: margin-left 100ms ease-in-out;
    }

    .sidebar.is-open {
        margin-left: 0%;
    }

    main.is-open {
        margin-left: 90%;
    }

    .sidebar .sidebar-header {
        display: none;
    }
}
.email-editor {
    background: var(--color-background);
    position: absolute;
    display: flex;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
}

.template-editor.inline {
    position: relative;
    top: unset;
    left: unset;
    right: unset;
    bottom: unset;
}

.email-editor-header {
    display: flex;
    gap: 20px;
    border-bottom: 1px solid var(--color-grey);
    padding: 10px 20px;
    align-items: center;
}

.email-editor-header .header-exit {
    border-right: 1px solid var(--color-grey);
    height: calc(100% + 20px);
    margin-top: -10px;
    font-size: 30px;
    color: var(--color-grey-hard);
    padding: 0 20px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.email-editor-header h3 {
    padding: 0;
    margin: 0;
    flex-grow: 1;
}

.email-editor-footer {
    background: var(--color-background);
    position: fixed;
    bottom: 10px;
    right: 10px;
    border-radius: var(--border-radius-outer);
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
    padding: 10px;
    display: flex;
    gap: 20px;
    align-items: center;
}

.email-editor-footer .publish-details {
    padding-left: 5px;
}

.email-editor-footer .publish-details > * {
    display: block;
}

.email-editor-footer .publish-details .publish-label {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 500;
}

.email-editor .ui-tabs {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 0;
    flex-grow: 0;
}

.email-editor .ui-tabs-panels {
    display: flex;
    flex-grow: 1;
}

.editor-html {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    min-height: 300px;
    position: relative;
    overflow: auto;
    padding: 20px;
}

.source-editor {    
    background: var(--color-editor);
    border-radius: var(--border-radius);
    border: 1px solid #1a1f2b;
    overflow: hidden;
    padding: 0;
    display: flex;
    flex-direction: column;
}

.source-editor .ui-tabs {
    border-bottom: 0;
}

.source-editor .ui-tabs .tab {
    color: var(--color-white);
}

.source-editor .ui-tabs .tab.selected,
.source-editor .ui-tabs .tab:hover {
    color: var(--color-white);
    border-color: var(--color-white);
}

.source-editor .ui-tabs-panels .panel {
    flex-direction: column;
}

.source-editor .editor-toolbar {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    padding: 10px;
}

.source-preview {
    display: grid;
}

.source-preview, .source-preview iframe {
    border-radius: var(--border-radius);
}

.source-preview iframe {
    width: 100%;
    height: 100%;
}